<template>
	<div class="system-notice">
		<w-navTab titleText="系统通知"></w-navTab>
		<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<w-title title="系统通知"></w-title>
			<div class="list">
				<div class="list-item" v-for="vo in noticesList">
					<div class="list-item-header">
						<div class="list-item-title">{{ vo.title }}</div>
						<div class="list-item-time">{{ vo.create_time }}</div>
					</div>
					<div class="list-item-content">{{ vo.content }}</div>
				</div>
			</div>
		</w-list>
	</div>
</template>

<script>
import WTitle from '@/components/Title';
import WList from '@/components/List';
import Index from '@/api/index';
export default {
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			page: 1,
			limit: 10,
			noticesList: []
		};
	},
	created() {
		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;
			let params = {
				page: this.page,
				limit: this.limit
			};
			Index.indexNotices(params)
				.then(result => {
					this.page++;
					this.noticesList = this.noticesList.concat(result.data.list);
					this.loading = false;

					if (this.noticesList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.noticesList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
	components: {
		WTitle,
		WList
	}
};
</script>

<style lang="less" scoped>
.system-notice {
	height: 100vh;
	
	.list-view {
		padding:10px;
		/deep/.more{
			background-image: url() !important;
			padding-right: 0;
			box-sizing: border-box;
		}
	}

	.list {
		padding-top: 10px;

		.list-item {
			padding: 10px;
			background-color: #fff;
			margin-bottom: 10px;
			border-radius: 6px;

			&:last-child {
				margin-bottom: 0;
			}

			.list-item-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 3px;

				.list-item-title {
					font-size: 18px;
					color: #333;
				}

				.list-item-time {
					font-size: 12px;
					color: #666;
				}
			}

			.list-item-content {
				font-size: 14px;
				color: #666;
			}
		}
	}
}
</style>
